import * as React from 'react';
import styled from 'styled-components';

import type View from 'views/logic/views/View';
import { Table, ControlLabel, Button } from 'components/bootstrap';
import { PaginatedList, Spinner, Select } from 'components/common';
import { FiltersRow, Modal as ConfirmDialog } from 'security-app/components/common';
import {
  usePagination,
  useSetPagination,
  useSelectedRows,
  useSelectedRowsDispatch,
} from 'security-app/components/common/contexts';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts';
import { useGetInvestigations, useBulkAddEvidence } from 'security-app/hooks/useInvestigationsAPI';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';
import type { ModalHandler } from 'security-app/components/Investigations/ModalHandler.type';
import type { InvestigationAPIType } from 'security-app/hooks/api/investigationsAPI.types';

import ListHeader from './ListHeader';
import ListRows from './ListRows';

const SelectedInvestigationsContainer = styled.div`
  margin-top: 1rem;
`;

const SelectedInvestigationsColumn = styled.div`
  margin: 0.8rem 0;
  display: flex;
  flexDirection: column;
  alignItems flex-start;
  gap: 0.5rem;
`;

type Props = {
  type: 'logs' | 'dashboards' | 'searches' | 'events',
  id: string,
  setShowModal: (showModal: boolean) => void,
  index?: string,
  searchMeta?: {
    searchTitle: string,
    isSearchRelative: boolean,
    saveAsAbsoluteSearch: (newTitle: string) => Promise<View>,
  },
};

const List = React.forwardRef(({ type, id, index, searchMeta, setShowModal }: Props, ref: React.Ref<ModalHandler>) => {
  const archived = false;
  const localPagination = usePagination();
  const setLocalPagination = useSetPagination();
  const selectedInvestigations = useSelectedRows();
  const { setSelectedInvestigationId, setShowDrawer, setCollapsed } = useInvestigationDrawer();
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';
  const selectedInvestigationsDispatch = useSelectedRowsDispatch();
  const [selectedInvToShow, setSelectedInvToShow] = React.useState<string>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState<boolean>(false);

  const { bulkAddEvidence } = useBulkAddEvidence();

  const submitEvidence = React.useCallback(async (investigations: InvestigationAPIType[], evidenceId: string) => {
    switch (type) {
      case 'logs':
        await bulkAddEvidence({ payload: { investigation_ids: investigations.map((inv: InvestigationAPIType) => inv.id), logs: [{ id: evidenceId, index }] } });
        break;
      case 'searches':
      case 'dashboards':
      case 'events':
        await bulkAddEvidence({ payload: { investigation_ids: investigations.map((inv: InvestigationAPIType) => inv.id), [type]: [evidenceId] } });
        break;
      default:
        break;
    }
  }, [bulkAddEvidence, index, type]);

  const showSelectedInvestigation = React.useCallback(() => {
    if (selectedInvToShow) {
      setSelectedInvestigationId(selectedInvToShow);
      setShowDrawer(true);
      setCollapsed(false);
    }
  }, [selectedInvToShow, setSelectedInvestigationId, setShowDrawer, setCollapsed]);

  const addEvidenceToSelectedInvestigations = React.useCallback(async (action: string = undefined) => {
    let evidenceId = id;

    if (type === 'searches') {
      const { searchTitle, isSearchRelative, saveAsAbsoluteSearch } = searchMeta;

      if (action === 'absolute') {
        setShowConfirmationDialog(false);
        const newView = await saveAsAbsoluteSearch(`Absolute - ${searchTitle}`);
        evidenceId = newView.id;
      } else if (action === 'relative') {
        setShowConfirmationDialog(false);
      } else if (action === 'cancel') {
        setShowConfirmationDialog(false);

        return;
      } else if (isSearchRelative) {
        setShowConfirmationDialog(true);

        return;
      }
    }

    setShowModal(false);

    const promise = submitEvidence(selectedInvestigations, evidenceId);

    await promise;

    showSelectedInvestigation();
  }, [
    id,
    type,
    searchMeta,
    selectedInvestigations,
    setShowModal,
    showSelectedInvestigation,
    submitEvidence,
  ]);

  React.useImperativeHandle(ref, () => ({
    onConfirm: () => addEvidenceToSelectedInvestigations(),
    onCancel: () => selectedInvestigationsDispatch({ type: 'clear' }),
  }));

  const { investigations, pagination, loadingInvestigations } = useGetInvestigations(localPagination, archived);

  const onPageChange = (newPage: number, newPageSize: number) => {
    setLocalPagination({ ...localPagination, page: newPage, perPage: newPageSize });
  };

  const removeFilter = (filterKey: string) => (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const auxFilters = { ...localPagination.filters };
    delete auxFilters[filterKey];

    setLocalPagination({ ...localPagination, filters: auxFilters });
  };

  const selectedInvOptions = React.useMemo(() => (
    selectedInvestigations
      ? selectedInvestigations.map((inv: InvestigationAPIType) => ({ label: inv.name, value: inv.id }))
      : []
  ), [selectedInvestigations]);

  React.useEffect(() => {
    const canShowSelectedInv = selectedInvOptions.some((inv: { value: string }) => inv.value === selectedInvToShow);

    if (!canShowSelectedInv) {
      setSelectedInvToShow(null);
    }

    if (selectedInvestigations.length > 0) {
      setSelectedInvToShow(selectedInvestigations[0].id);
    }
  }, [selectedInvestigations, selectedInvOptions, selectedInvToShow]);

  const buttons = React.useMemo(() => (
    <>
      <Button onClick={() => addEvidenceToSelectedInvestigations('cancel')}>Cancel</Button>
      <Button onClick={() => addEvidenceToSelectedInvestigations('relative')}>Save as Relative</Button>
      <Button bsStyle="success" onClick={() => addEvidenceToSelectedInvestigations('absolute')}>Save as Absolute</Button>
    </>
  ), [addEvidenceToSelectedInvestigations]);

  return (
    <>
      <PaginatedList activePage={localPagination.page}
                     pageSize={localPagination.perPage}
                     onChange={onPageChange}
                     totalItems={pagination.total}
                     useQueryParameter={false}>
        <FiltersRow filters={localPagination.filters} onRemove={removeFilter} />
        <Table condensed hover className="table table-striped" data-testid="open-investigations-list">
          <ListHeader investigations={investigations} />
          <tbody>
            {loadingInvestigations ? <tr><td colSpan={2} aria-label="Spinner"><Spinner text="Loading investigations ..." /></td></tr> : (
              <ListRows investigations={investigations} />
            )}
          </tbody>
        </Table>
      </PaginatedList>
      {(!!selectedInvOptions.length && isSecurityPerspective) && (
        <SelectedInvestigationsContainer>
          <ControlLabel>Select an investigation to open in drawer</ControlLabel>
          <SelectedInvestigationsColumn>
            {selectedInvOptions.map((inv: { label: string, value: string }) => (
              <span key={inv.value}>{inv.label}</span>
            ))}
          </SelectedInvestigationsColumn>
        </SelectedInvestigationsContainer>
      )}
      {(!!selectedInvOptions.length && !isSecurityPerspective) && (
        <div>
          <ControlLabel>Select an investigation to open in drawer</ControlLabel>
          <Select id="showInvestigation"
                  inputProps={{ name: 'showInvestigation', 'data-testid': 'showInvestigation' }}
                  placeholder="Select one ..."
                  clearable
                  matchProp="value"
                  onChange={(value: string) => setSelectedInvToShow(value)}
                  value={selectedInvOptions[0].label}
                  options={selectedInvOptions} />
        </div>
      )}
      <ConfirmDialog show={showConfirmationDialog}
                     title="Relative search as evidence"
                     onClose={() => addEvidenceToSelectedInvestigations('cancel')}
                     confirmDialog
                     buttons={buttons}>
        <div>
          The search you are trying to add is relative to the current time. This means that the results will change.
          <br />
          <br />
          Would you like to make an absolute copy of this search and add it to the investigation?
        </div>
      </ConfirmDialog>
    </>
  );
});

List.defaultProps = {
  index: undefined,
  searchMeta: undefined,
};

export default List;
